/* eslint-disable no-template-curly-in-string */
import { setLocale } from 'yup'

setLocale({
  mixed: {
    required: '${path} è un campo obbligatorio',
  },
  string: {
    email: '${path} deve essere una mail valida',
    url: '${path} deve essere un URL valido',
  },
})
