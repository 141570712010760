import { Field, Formik } from 'formik'
import * as Yup from 'yup'
import { BaseAdminedUser, AdminedUser, User } from '../types'
import { CheckBoxField, InputField, transformErrorForForm } from './form'
import { format as formatDate } from 'date-fns'
import { useAuthUser } from 'use-eazy-auth'

const initialValues: BaseAdminedUser = {
  email: '',
  name: '',
  is_active: true,
  is_staff: false,
  is_superuser: false,
}

const UserSchema = Yup.object().shape({
  email: Yup.string().required().label('Email'),
  name: Yup.string().label('Nome'),
  is_active: Yup.boolean().label('Attivo'),
  is_staff: Yup.boolean().label('Staff'),
  is_superuser: Yup.boolean().label('Superuser'),
})

interface Props {
  user?: AdminedUser
  save: (user: AdminedUser) => Promise<unknown>
}

export default function UserForm({ save, user }: Props) {
  const { user: authUser } = useAuthUser<User>()
  return (
    <Formik
      validationSchema={UserSchema}
      onSubmit={(user, { setErrors }) =>
        save({
          ...user,
        }).catch((err) => {
          setErrors(transformErrorForForm(err))
        })
      }
      validateOnMount
      initialValues={(user ?? initialValues) as AdminedUser}
    >
      {({ handleSubmit, isSubmitting, isValid }) => (
        <form onSubmit={handleSubmit} className="hm-form">
          <div className="row">
            <div className="col-md-12 mb-4 d-flex justify-content-between align-items-center">
              <h4>Dati utente</h4>
            </div>
            <div className="col-md-6">
              <Field label="Nome" name="name" component={InputField} />
            </div>
            <div className="col-md-6">
              <Field label="E-mail" name="email" component={InputField} />
            </div>
            <div className="col-md-3 mt-2">
              <Field
                label="Attivo?"
                component={CheckBoxField}
                name="is_active"
              />
            </div>
            <div className="col-md-3 mt-2">
              <Field label="Staff?" component={CheckBoxField} name="is_staff" />
            </div>
            <div className="col-md-3 mt-2">
              {authUser!.is_superuser && (
                <Field
                  label="Superuser?"
                  component={CheckBoxField}
                  name="is_superuser"
                />
              )}
            </div>
            {user?.id && (
              <div className="col-md-6">
                <div className="form-grouo">
                  <label>Ultimo accesso</label>
                  <input
                    className="form-control"
                    value={
                      user.last_login
                        ? formatDate(
                            new Date(user.last_login),
                            'yyyy-MM-dd HH:mm'
                          )
                        : ''
                    }
                    readOnly
                  />
                </div>
              </div>
            )}
            <div className="d-flex justify-content-end mt-2">
              <button
                type="submit"
                disabled={isSubmitting || !isValid}
                className="btn btn-success"
              >
                <i className="text-white me-1 bi bi-save-fill"></i> Salva
              </button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  )
}
