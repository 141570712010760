import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { Spinner } from 'reactstrap'
import ErrorBoundary from '../ErrorBoundary'
import Header from './Header'
import Page from './Page'
import SideBar from './SideBar'

export default function Layout() {
  return (
    <div className="h-100 overflow-hidden d-flex">
      <SideBar />
      <ErrorBoundary inRouter>
        <Suspense
          fallback={
            <Page padded>
              <Header />
              <div className="flex-1 text-center mt-4">
                <Spinner color="primary" />
              </div>
            </Page>
          }
        >
          <Outlet />
        </Suspense>
      </ErrorBoundary>
    </div>
  )
}
