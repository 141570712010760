import { Field, Formik } from 'formik'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { InputField, transformErrorForForm } from '../../components/form'
import Header from '../../components/Header'
import Page from '../../components/Page'
import { useChangePassword } from '../../hooks/account'

const ChangePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password è un campo obbligatorio')
    .min(8, 'La password deve essere lunga almeno 8 caratteri.'),
  repeatPassword: Yup.string()
    .required('Conferma Password è un campo obbligatorio')
    .oneOf([Yup.ref('password'), null], 'Le password non corrispondono.'),
})

const initialValues = {
  password: '',
  repeatPassword: '',
}

export default function ProfileChangePassword() {
  const changePassword = useChangePassword()
  const [passwordChanged, setPasswordChanged] = useState(false)
  return (
    <Page>
      <Header margins>
        <div className="d-flex align-items-center">
          <Link className="no-link" to="/profile">
            <h5 className="m-0 breadcrumb-link">Profilo</h5>
          </Link>
          <div className="ps-3">/</div>
          <h2 className="m-0 ps-3">Cambia Password</h2>
        </div>
      </Header>
      <div>
        <Formik
          onSubmit={(data, { setErrors, setTouched }) =>
            changePassword({ password: data.password }).then(
              () => {
                setPasswordChanged(true)
              },
              (error) => {
                setErrors(transformErrorForForm(error))
              }
            )
          }
          validationSchema={ChangePasswordSchema}
          initialValues={initialValues}
        >
          {({ handleSubmit, isSubmitting, isValid, dirty, setTouched }) => (
            <div className="mt-4" style={{ maxWidth: 500, margin: 'auto' }}>
              <div>
                <form onSubmit={handleSubmit} className="mt-4">
                  <Field
                    label="Password"
                    type="password"
                    name="password"
                    component={InputField}
                    onChange={() => setPasswordChanged(false)}
                  />
                  <Field
                    label="Conferma Password"
                    type="password"
                    name="repeatPassword"
                    component={InputField}
                    onChange={() => setPasswordChanged(false)}
                  />
                  <div className="d-flex justify-content-end mt-3">
                    <button
                      type="submit"
                      disabled={isSubmitting || !isValid}
                      className="btn btn-primary"
                    >
                      Cambia Password
                    </button>
                  </div>
                  {passwordChanged && (
                    <div className="alert alert-success mt-3">
                      Password cambiata con successo
                    </div>
                  )}
                </form>
              </div>
            </div>
          )}
        </Formik>
      </div>
    </Page>
  )
}
