import axios from 'axios'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useAuthCallPromise } from 'use-eazy-auth'
import { Slide } from '../types'
import { makeFormDataEncoder } from './utils'

export function useSlides(params: Record<string, any> = {}) {
  return useQuery<Slide[]>(
    ['slides', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/admin/slides/?${new URLSearchParams(params).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useSlide(id: number) {
  return useQuery<Slide>(
    ['slide', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/admin/slides/${id}/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    )
  )
}

export function useDeleteSlide() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/admin/slides/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['slides'])
      },
    }
  )
}

const makeSlideFormData = makeFormDataEncoder({
  fileFields: ['image'],
  excludeFields: [],
})

export function useCreateSlide() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (slide: Slide) =>
        axios
          .post(`/api/admin/slides/`, makeSlideFormData(slide), {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Slide)
    ),
    {
      onSuccess(savedSlide) {
        client.invalidateQueries(['slides'])
        client.setQueryData(['slide', savedSlide.id], savedSlide)
      },
    }
  )
}

export function useUpdateSlide() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (slide: Slide) =>
        axios
          .put(`/api/admin/slides/${slide.id}/`, makeSlideFormData(slide), {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Slide)
    ),
    {
      onSuccess(savesSlide) {
        client.invalidateQueries(['slides'])
        client.setQueryData(['slide', savesSlide.id], savesSlide)
      },
    }
  )
}
