import classNames from 'classnames'

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  margins?: boolean
}

export default function Header({ margins, ...props }: Props) {
  return (
    <div
      {...props}
      className={classNames(
        'd-flex align-items-center justify-content-between border-bottom border-dark page-header',
        props.className ?? '',
        {
          'mx-4': margins,
        }
      )}
    />
  )
}
