import axios from 'axios'
import { useQuery } from '@tanstack/react-query'

export function useCheckActivationToken(token: string) {
  return useQuery(
    ['checkActivationToken'],
    () =>
      axios.get(`/api/admin/check-activation-token/${token}/`).then((r) => r.data as void),
    {
      suspense: false,
      useErrorBoundary: false,
      staleTime: 0,
    }
  )
}

export function activateAccount(token: string, password: string) {
  return axios
    .post(`/api/admin/activate-account/${token}/`, { password })
    .then((r) => r.data as void)
}
