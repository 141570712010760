import { Link, useNavigate, useParams } from 'react-router-dom'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { AdminedUser } from '../../types'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  useAdminedUser,
  useDeleteAdminedUser,
  useReSendUserActivationLink,
  useUpdateAdminedUser,
} from '../../hooks/users'
import UserForm from '../../components/UserForm'
import Page from '../../components/Page'
import Header from '../../components/Header'

export default function UserEdit() {
  const { id } = useParams()
  const { data: dataUser } = useAdminedUser(+id!)
  const deleteUser = useDeleteAdminedUser()
  const updateUser = useUpdateAdminedUser()
  const reSendUserActivationLink = useReSendUserActivationLink()
  const [modalRm, modalRmActions] = useModalTrigger<AdminedUser>()
  const [modalSendLink, modalSendLinkActions] = useModalTrigger()
  const navigate = useNavigate()

  return (
    <Page>
      <Header margins>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/users">Utenti</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {dataUser!.name}
            </li>
          </ol>
        </nav>
        <div className="d-flex align-items-center">
          {!dataUser!.last_login && dataUser!.is_active && (
            <button
              className="btn btn-primary me-2"
              onClick={() => modalSendLinkActions.open()}
            >
              <i className="bi bi-send"></i> Re Invita
            </button>
          )}
          <button
            className="btn btn-danger"
            onClick={() => modalRmActions.open(dataUser)}
          >
            <i className="bi bi-trash3-fill"></i> Elimina
          </button>
        </div>
      </Header>
      <div className="py-3 overflow-y-auto flex-1">
        <UserForm
          user={dataUser}
          save={(formUser) =>
            updateUser.mutateAsync(formUser).then(() =>
              toast.success('Utente salvato con successo!', {
                position: toast.POSITION.TOP_RIGHT,
                theme: 'colored',
              })
            )
          }
        />
      </div>
      <Modal centered isOpen={modalRm.isOpen} toggle={modalRmActions.toggle}>
        <ModalHeader toggle={modalRmActions.toggle}>Elimina</ModalHeader>
        <ModalBody>
          {modalRm.value && (
            <div className="pt-3 pb-3">
              Sei sicuro di voler eliminare l'utente {modalRm.value.name} ?
            </div>
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <button
            onClick={modalRmActions.toggle}
            className="btn btn-outline-primary"
          >
            Annulla
          </button>
          <button
            className="btn btn-danger"
            disabled={deleteUser.isLoading}
            onClick={async () => {
              await deleteUser.mutateAsync(modalRm.value!.id)
              navigate('/users')
            }}
          >
            <i className="bi bi-trash3-fill"></i> Elimina
          </button>
        </ModalFooter>
      </Modal>
      <Modal
        centered
        isOpen={modalSendLink.isOpen}
        toggle={modalRmActions.toggle}
      >
        <ModalHeader toggle={modalSendLinkActions.toggle}>Invia Link</ModalHeader>
        <ModalBody>
          <div className="pt-3 pb-3">
            Re inviare il link di invito a <b>{dataUser!.email}</b>?
          </div>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <button
            onClick={modalSendLinkActions.toggle}
            className="btn btn-outline-primary"
          >
            Annulla
          </button>
          <button
            className="btn btn-success"
            disabled={reSendUserActivationLink.isLoading}
            onClick={async () => {
              await reSendUserActivationLink.mutateAsync(dataUser!.id)
              toast.success('Link inviato con successo!', {
                position: toast.POSITION.TOP_RIGHT,
                theme: 'colored',
              })
              modalSendLinkActions.close()
            }}
          >
            <i className="bi bi-send"></i> Invia
          </button>
        </ModalFooter>
      </Modal>
    </Page>
  )
}
