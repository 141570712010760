import { memo } from 'react'
import { LANGS, MAIN_LANG } from '../consts'

interface MissingTranslationsProps {
  data: Record<string, any>
  fields: string[]
}

function MissingTranslations({ data, fields }: MissingTranslationsProps) {
  const filledFields = fields.filter((f) => data[f][MAIN_LANG])

  const missingLangs = LANGS.filter((l) => l !== MAIN_LANG).filter((l) =>
    filledFields.some((f) => !data[f][l])
  )

  if (missingLangs.length === 0) {
    return null
  }

  return (
    <ul>
      {missingLangs.map((lang) => (
        <li className="text-warning" key={lang}>
          {lang} incompleta.
        </li>
      ))}
    </ul>
  )
}

export default memo(MissingTranslations)
