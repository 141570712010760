import classNames from 'classnames'

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  margins?: boolean
}

export default function Footer({ margins, ...props }: Props) {
  return (
    <div
      {...props}
      className={classNames(
        'page-footer',
        props.className ?? '',
        {
          'mx-4': margins,
        }
      )}
    />
  )
}
