import { Route, Routes } from 'react-router-dom'
import NotFound from '../../components/NotFound'
import ProfileChangePassword from './ProfileChangePassword'
import ProfileInfo from './ProfileInfo'

export default function Profile() {
  return (
    <Routes>
      <Route index element={<ProfileInfo />} />
      <Route path="change-password" element={<ProfileChangePassword />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
