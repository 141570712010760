import classNames from 'classnames'

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  padded?: boolean
}

export default function Page({ padded = false, ...props }: Props) {
  return (
    <div
      {...props}
      className={classNames(
        'flex-1 d-flex flex-column overflow-hidden',
        props.className ?? '',
        {
          'px-4': padded,
        }
      )}
    >
      {props.children}
    </div>
  )
}
