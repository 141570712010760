import styles from './StickyTable.module.css'

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>

export default function SticyTable(props: Props) {
  return (
    <div
      {...props}
      className={`table-responsive ${styles.StickyTable} ${
        props.className ?? ''
      }`}
    >
      <table className="table table-striped">{props.children}</table>
    </div>
  )
}
