import { Link } from 'react-router-dom'
import { useAuthUser } from 'use-eazy-auth'
import Header from '../../components/Header'
import Page from '../../components/Page'
import { User } from '../../types'

export default function ProfileInfo() {
  const { user } = useAuthUser<User>()

  return (
    <Page padded>
      <Header>
        <h2 className="m-0">Profilo</h2>
      </Header>
      <div className="hm-form mt-5">
        <p>
          {user!.name}
          <br />
          {user!.email}
        </p>
        <div>
          <Link
            style={{ width: 'max-content' }}
            className="btn btn-primary"
            to="change-password"
          >
            Cambia password
          </Link>
        </div>
      </div>
    </Page>
  )
}
