import axios from 'axios'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useAuthCallPromise } from 'use-eazy-auth'
import { Serie, PaginatedDJResponse } from '../types'
import { makeFormDataEncoder } from './utils'

export function useSeries(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<Serie>>(
    ['series', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/admin/series/?${new URLSearchParams(params).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useSerie(id: number) {
  return useQuery<Serie>(
    ['serie', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/admin/series/${id}/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    )
  )
}

export function useDeleteSerie() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/admin/series/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['series'])
      }
    }
  )
}

const makeSerieFormData = makeFormDataEncoder({
  fileFields: ['brand_logo','cover_image'],
  excludeFields: [
  ],
})

export function useCreateSerie() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (serie: Serie) =>
        axios
          .post(`/api/admin/series/`, makeSerieFormData(serie), {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Serie)
    ),
    {
      onSuccess(savedSerie) {
        client.invalidateQueries(['series'])
        client.setQueryData(['serie', savedSerie.id], savedSerie)
      },
    }
  )
}

export function useUpdateSerie() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (serie: Serie) =>
        axios
          .put(`/api/admin/series/${serie.id}/`, makeSerieFormData(serie), {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Serie)
    ),
    {
      onSuccess(savedSerie) {
        client.invalidateQueries(['series'])
        client.setQueryData(['serie', savedSerie.id], savedSerie)
      },
    }
  )
}
