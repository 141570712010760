import classNames from 'classnames'
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
} from 'react'

export interface SorterResult {
  getDirection(field: string): string | null
  handleSortChange(field: string): void
}

export function SortControl({ field }: { field: string }) {
  const { getDirection, handleSortChange } = useContext(SortContext)
  const direction = getDirection(field)

  return (
    <div className="ms-1 text-t-dark-grey" style={{ whiteSpace: 'nowrap' }}>
      {(direction === 'up' || !direction) && (
        <i
          onClick={() => handleSortChange(field)}
          className={classNames('bi ms-1 bi-sort-up cursor-pointer', {
            'opacity-05': !direction,
          })}
        />
      )}
      {direction === 'down' && (
        <i
          onClick={() => handleSortChange(field)}
          className={classNames('bi ms-1 bi-sort-down cursor-pointer', {
            // 'text-primary': direction === 'up',
          })}
        />
      )}
    </div>
  )
}

function getSortData(sort: string | null) {
  if (!sort) {
    return {
      direction: null,
      sortedField: null,
    }
  }
  const isDown = sort.indexOf('-') === 0
  const sortedField = isDown ? sort.slice(1) : sort
  return {
    sortedField,
    direction: isDown ? 'down' : 'up',
  }
}

function useSorter(
  value: string | null = null,
  onSortChange: (sort: string) => void
): SorterResult {
  const getDirection = useCallback(
    (field: string) => {
      const { direction, sortedField } = getSortData(value)
      if (sortedField === field) {
        return direction
      }
      return null
    },
    [value]
  )

  const handleSortChange = useCallback(
    (field: string) => {
      let nextSort = ''
      const { direction, sortedField } = getSortData(value)
      if (sortedField !== field) {
        nextSort = `-${field}`
      } else if (direction === 'down') {
        nextSort = field
      }
      onSortChange(nextSort)
    },
    [onSortChange, value]
  )

  return useMemo(
    () => ({
      getDirection,
      handleSortChange,
    }),
    [getDirection, handleSortChange]
  )
}

const SortContext = createContext<SorterResult>(null as never)

export interface SorterProps {
  children: ReactNode
  value: null | string
  onSortChange(sort: string): void
}

export default function Sorter({ value, onSortChange, children }: SorterProps) {
  return (
    <SortContext.Provider value={useSorter(value, onSortChange)}>
      {children}
    </SortContext.Provider>
  )
}
