import { Route, Routes } from 'react-router-dom'
import { useAuthUser } from 'use-eazy-auth'
import NotFound from '../../components/NotFound'
import { User } from '../../types'
import ContentCreate from './ContentCreate'
import ContentEdit from './ContentEdit'
import ContentsList from './ContentsList'

export default function Contents() {
  const { user } = useAuthUser<User>()
  return (
    <Routes>
      <Route index element={<ContentsList />} />
      {user!.is_superuser && <Route path="new" element={<ContentCreate />} />}
      <Route path=":id" element={<ContentEdit />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
