import axios from 'axios'
import { useQuery } from '@tanstack/react-query'

export function recoverPassword(email: string) {
  return axios
    .post('/api/admin/recover-password/', { email })
    .then((r) => r.data as void)
}

export function useCheckResetPasswordToken(token: string) {
  return useQuery(
    ['checkResetPasswordToken'],
    () =>
      axios.get(`/api/admin/check-reset-token/${token}/`).then((r) => r.data as void),
    {
      suspense: false,
      useErrorBoundary: false,
      staleTime: 0,
    }
  )
}

export function resetPassword(token: string, password: string) {
  return axios
    .post(`/api/admin/reset-password/${token}/`, { password })
    .then((r) => r.data as void)
}
