import axios from 'axios'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useAuthCallPromise } from 'use-eazy-auth'
import {
  BaseBlockVitaArchivio,
  BlockVitaArchivio,
  PaginatedDJResponse,
  VitaArchivio,
} from '../types'
import { makeFormDataEncoder } from './utils'

export function useViteArchivio(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<VitaArchivio>>(
    ['vite-archivio', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/admin/vite-archivio/?${new URLSearchParams(
                params
              ).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useVitaArchivio(id: number) {
  return useQuery<VitaArchivio>(
    ['extra', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/admin/vite-archivio/${id}/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    )
  )
}

export function useDeleteVitaArchivio() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/admin/vite-archivio/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['vite-archivio'])
      },
    }
  )
}

const makeSerieFormData = makeFormDataEncoder({
  fileFields: ['cover_image'],
  excludeFields: [
    'cover_image_thumb',
    'cover_image_medium',
    'cover_image_preview',
  ],
})

export function useCreateVitaArchivio() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (extra: VitaArchivio) =>
        axios
          .post(`/api/admin/vite-archivio/`, makeSerieFormData(extra), {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as VitaArchivio)
    ),
    {
      onSuccess(savedExtra) {
        client.invalidateQueries(['vite-archivio'])
        client.setQueryData(['vita-archivio', savedExtra.id], savedExtra)
      },
    }
  )
}

export function useUpdateVitaArchivio() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (extra: VitaArchivio) =>
        axios
          .put(
            `/api/admin/vite-archivio/${extra.id}/`,
            makeSerieFormData(extra),
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((r) => r.data as VitaArchivio)
    ),
    {
      onSuccess(savedExtra) {
        client.invalidateQueries(['vite-archivio'])
        client.setQueryData(['vita-archivio', savedExtra.id], savedExtra)
      },
    }
  )
}

export function useDeleteBlockVitaArchivio() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/admin/blocks-vite-archivio/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['blocks-vite-archivio'])
        client.invalidateQueries(['block-vita-archivio'])
        client.invalidateQueries(['vite-archivio'])
      },
    }
  )
}

export function useUpdateBlockVitaArchivio() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (block: BlockVitaArchivio) =>
        axios
          .put(
            `/api/admin/blocks-vite-archivio/${block.id}/`,
            makeSerieFormData(block),
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((r) => r.data as BlockVitaArchivio)
    ),
    {
      onSuccess(savedBlock) {
        client.invalidateQueries(['blocks-vite-archivio'])
        client.setQueryData(['block-vita-archivio', savedBlock.id], savedBlock)
      },
    }
  )
}

export function useCreateBlockVitaArchivio() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (block: BaseBlockVitaArchivio) =>
        axios
          .post(`/api/admin/blocks-vite-archivio/`, makeSerieFormData(block), {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as BaseBlockVitaArchivio)
    ),
    {
      onSuccess(savedBlock) {
        client.invalidateQueries(['blocks-vite-archivio'])
        client.setQueryData(['block-vita-archivio', savedBlock.id], savedBlock)
      },
    }
  )
}
