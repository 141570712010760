import axios from 'axios'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useAuthCallPromise } from 'use-eazy-auth'
import { Extra, PaginatedDJResponse, ExtraImage, BaseExtraImage } from '../types'
import { makeFormDataEncoder } from './utils'

export function useExtras(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<Extra>>(
    ['extras', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/admin/extras/?${new URLSearchParams(params).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useExtra(id: number) {
  return useQuery<Extra>(
    ['extra', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/admin/extras/${id}/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    )
  )
}

export function useExtraImage(id: number){
  return useQuery<ExtraImage>(
    ['extra_image', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/admin/extra-images/${id}/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    )
  )
}

export function useDeleteExtra() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/admin/extras/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['extras'])
      },
    }
  )
}

export function useDeleteExtraImage() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/admin/extra-images/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['extra_images'])
        client.invalidateQueries(['extra_image'])
      },
    }
  )
}

const makeSerieFormData = makeFormDataEncoder({
  fileFields: ['cover_image'],
  excludeFields: [
  ],
})

const makeExtraImageFormData = makeFormDataEncoder({
  fileFields: ['image'],
  excludeFields: [
  ],
})

export function useCreateExtra() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (extra: Extra) =>
        axios
          .post(`/api/admin/extras/`, makeSerieFormData(extra), {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Extra)
    ),
    {
      onSuccess(savedExtra) {
        client.invalidateQueries(['extras'])
        client.setQueryData(['extra', savedExtra.id], savedExtra)
      },
    }
  )
}

export function useCreateExtraImage() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (extraImage: BaseExtraImage) =>
        axios
          .post(`/api/admin/extra-images/`, makeExtraImageFormData(extraImage), {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as ExtraImage)
    ),
    {
      onSuccess(savedExtraImage) {
        client.invalidateQueries(['extra_images'])
        client.setQueryData(['extra_image', savedExtraImage.id], savedExtraImage)
      },
    }
  )
}

export function useUpdateExtra() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (extra: Extra) =>
        axios
          .put(`/api/admin/extras/${extra.id}/`, makeSerieFormData(extra), {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Extra)
    ),
    {
      onSuccess(savedExtra) {
        client.invalidateQueries(['extras'])
        client.setQueryData(['extra', savedExtra.id], savedExtra)
      },
    }
  )
}


export function useUpdateExtraImage() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (extraImage: ExtraImage) =>
        axios
          .put(`/api/admin/extra-images/${extraImage.id}/`, makeExtraImageFormData(extraImage), {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as ExtraImage)
    ),
    {
      onSuccess(savedExtraImage) {
        client.invalidateQueries(['extra_images'])
        client.setQueryData(['extra_image', savedExtraImage.id], savedExtraImage)
      },
    }
  )
}