import { Route, Routes } from 'react-router-dom'
import NotFound from '../../components/NotFound'
import UserCreate from './UserCreate'
import UserEdit from './UserEdit'
import UsersList from './UsersList'

export default function Users() {
  return (
    <Routes>
      <Route index element={<UsersList />} />
       <Route path="new" element={<UserCreate />} />
      <Route path=":id" element={<UserEdit />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}