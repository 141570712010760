import axios from 'axios'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useAuthCallPromise } from 'use-eazy-auth'
import { Author, PaginatedDJResponse } from '../types'

export function useAuthors(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<Author>>(
    ['authors', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/admin/authors/?${new URLSearchParams(params).toString()}`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useAuthorsForSelect(params: Record<string, any> = {}) {
  return useQuery<Author[]>(
    ['authors-for-select', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/admin/authors-home/?${new URLSearchParams(params).toString()}`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useAuthor(id: number) {
  return useQuery<Author>(
    ['author', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/admin/authors/${id}/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    )
  )
}

export function useDeleteAuthor() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/admin/authors/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['authors'])
      },
    }
  )
}

export function useCreateAuthor() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (author: Author) =>
        axios
          .post(`/api/admin/authors/`, author, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Author)
    ),
    {
      onSuccess(savedAuthor) {
        client.invalidateQueries(['authors'])
        client.setQueryData(['author', savedAuthor.id], savedAuthor)
      },
    }
  )
}

export function useUpdateAuthor() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (author: Author) =>
        axios
          .put(`/api/admin/authors/${author.id}/`, author, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Author)
    ),
    {
      onSuccess(savedAuthor) {
        client.invalidateQueries(['authors'])
        client.setQueryData(['author', savedAuthor.id], savedAuthor)
      },
    }
  )
}
