
export default function RunTimeError({ error } : { error: Error }) {
  return (
    <div className="text-center mt-2 flex-1">
      <h1>
        Something went wrong
      </h1>
    </div>
  )
}
