import { Link, useNavigate } from 'react-router-dom'
import Header from '../../components/Header'
import Page from '../../components/Page'
import UserForm from '../../components/UserForm'
import { useCreateAdminedUser } from '../../hooks/users'

export default function UserCreate() {
  const createUser = useCreateAdminedUser()
  const navigate = useNavigate()

  return (
    <Page>
      <Header margins>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/users">Utenti</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Aggiungi
            </li>
          </ol>
        </nav>
      </Header>
      <div className="py-3 overflow-y-auto flex-1">
        <UserForm
          save={async (user) => {
            const savedUser = await createUser.mutateAsync(user)
            navigate(`/users/${savedUser.id}`)
          }}
        />
      </div>
    </Page>
  )
}
