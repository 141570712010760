import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useAuthCallPromise } from 'use-eazy-auth'
import { AdminedUser, PaginatedDJResponse } from '../types'
import { QueryOnlyOptions } from './utils'

export function useAdminedUsers(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<AdminedUser>>(
    ['users', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/admin/users/?${new URLSearchParams(params).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useAdminedUser(id: number, queryOptions?: QueryOnlyOptions) {
  return useQuery<AdminedUser>(
    ['user', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/admin/users/${id}/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    queryOptions
  )
}

export function useDeleteAdminedUser() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/admin/users/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['users'])
      },
    }
  )
}

export function useCreateAdminedUser() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (user: AdminedUser) =>
        axios
          .post(`/api/admin/users/`, user, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as AdminedUser)
    ),
    {
      onSuccess(savedUser) {
        client.invalidateQueries(['users'])
        client.setQueryData(['user', savedUser.id], savedUser)
      },
    }
  )
}

export function useUpdateAdminedUser() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (user: AdminedUser) =>
        axios
          .put(`/api/admin/users/${user.id}/`, user, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as AdminedUser)
    ),
    {
      onSuccess(savedUser) {
        client.invalidateQueries(['users'])
        client.setQueryData(['user', savedUser.id], savedUser)
      },
    }
  )
}

export function useReSendUserActivationLink() {
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios
          .put(`/api/admin/users/${id}/resend-activation-link/`, {}, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as void)
    )
  )
}
