import axios from 'axios'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useAuthCallPromise } from 'use-eazy-auth'
import { Content, PaginatedDJResponse } from '../types'

export function useContents(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<Content>>(
    ['contents', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/admin/contents/?${new URLSearchParams(params).toString()}`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useContent(id: number) {
  return useQuery<Content>(
    ['content', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/admin/contents/${id}/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    )
  )
}

export function useDeleteContent() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/admin/contents/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['contents'])
        client.invalidateQueries(['content'])
      },
    }
  )
}

export function useCreateContent() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (content: Content) =>
        axios
          .post(`/api/admin/contents/`, content, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Content)
    ),
    {
      onSuccess(savedContent) {
        client.invalidateQueries(['contents'])
        client.setQueryData(['content', savedContent.id], savedContent)
      },
    }
  )
}

export function useUpdateContent() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (content: Content) =>
        axios
          .put(`/api/admin/contents/${content.id}/`, content, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as Content)
    ),
    {
      onSuccess(savedContent) {
        client.invalidateQueries(['contents'])
        client.setQueryData(['content', savedContent.id], savedContent)
      },
    }
  )
}
